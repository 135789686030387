<template>
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">

      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <v-icon color="primary">mdi-map-marker-outline</v-icon>
        </v-avatar>
        <h2 class="mb-0">{{ $t("Address.add") }}</h2>
      </div>

      <v-btn
        outlined
        color="primary"
        class="text-capitalize font-600"
        :to="`/entities/${$route.params.entidadId}/account/addresses`"
      >
        {{ $t("Address.back") }}
      </v-btn>
    </div>
    <base-card>
      <div class="pa-4">
        <v-row>
          <!-- LISTADO DE UBICACIONES -->
          <v-col cols="12" md="12" lg="12" xl="12">
            <p class="text-14 mb-1">{{ $t("Address.address") }}</p>
            <v-autocomplete
              v-model="address.location"
              :items="autoCompleteItems"
              :placeholder="$t('Address.placeHolder')"
              item-text="address"
              outlined
              return-object
              dense
              clearable
              :search-input.sync="location"
              @click:clear="cleanAutoCompleteItems()"
              @update:search-input="(text) => waitUserTyping(text)"
              @change="(item) => addMarker(item)"
              :filter="(item) => item"
              hide-details
            ></v-autocomplete>
          </v-col>

          <!-- FIN DE LISTADO DE UBICACIONES -->
          <v-col cols="12" md="12" lg="12" xl="12">
            <!-- RENDERIZADO DEL MAPA -->
            <div
              class="google-map"
              ref="mapName"
              style="width: 100%; height: 400px"
            ></div>
            <!-- FIN DE RENDERIZADO DEL MAPA -->
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="font-600 text-capitalize"
              @click="() => addAddress()"
            >
              {{ $t('Address.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  data() {
    return {
      address: {},
      markers: [],
      places: [],
      placesService: null,
      autoCompleteItems: [],
      location: "",
      map: null,
      marker: null,
      isEditing: false,
      timeout: null,
    };
  },
  mounted() {
    const element = this.$refs.mapName;

    const options = {
      zoom: 8,
      center: { lat: 21.9551186, lng: -98.7130316 },
      mapTypeControl: false,
      streetViewControl: false,
    };

    this.map = new window.google.maps.Map(element, options);

    this.placesService = new window.google.maps.places.PlacesService(this.map);

    let ubicacionId = this.$route.params.ubicacionId;

    if (ubicacionId) {
      axios
        .get(`api/v1/private/addresses/${ubicacionId}`)
        .then((res) => {
          let address = _.get(res, "data.data", {});

          let { latitud, longitud, ubicacion } = address;

          let location = {
            address: ubicacion,
            lat: latitud,
            lng: longitud,
          };

          this.address = {
            ...address,
            location
          };

          this.autoCompleteService(location.address);
          
          this.addMarker(location);

          this.isEditing = true;
        })
        .catch(() => {

          this.isEditing = false;

          const entidadId = this.$route.params.entidadId;

          this.$router.push(`/entities/${entidadId}/account/addresses`).catch(() => {});
        });
    }
  },
  methods: {
    addAddress() {
      let location = this.address.location;

      if(this.isEditing){

        _.set(this.address, 'ubicacion', location.address);
        _.set(this.address, 'latitud', location.lat);
        _.set(this.address, 'longitud', location.lng);

        axios
          .put(`api/v1/private/addresses`, this.address)
          .then(() => {
            const entidadId = this.$route.params.entidadId;

            this.$router.push(`/entities/${entidadId}/account/addresses`).catch(() => {});
          })
          .catch(() => {
            // DOES NOTHING
          });
      }else{

        let obj = {
          ubicacion: location.address,
          latitud: location.lat,
          longitud: location.lng
        }

        axios
          .post(`api/v1/private/addresses`, obj)
          .then(() => {
            const entidadId = this.$route.params.entidadId;

            this.$router.push(`/entities/${entidadId}/account/addresses`).catch(() => {});
          })
          .catch(() => {
            // DOES NOTHING
          })
          .finally(() => {
            this.$emit('refresh');
          })
      }
    },
    waitUserTyping(query){
      // VERIFICA SI EXISTE UN TIMEOUT
      if(this.timeout){

        // SI YA EXISTE UN TIMEOUT SE CANCELA
        clearTimeout(this.timeout);
      }

      // SE CREA UN NUEVO TIMEOUT
      this.timeout = setTimeout(()=>{

        // AL COMPLETARSE EL TIMEOUT SE EJECUTA EL SERVICIO DE BUSQUEDA
        this.autoCompleteService(query);
      }, 500);
    },
    autoCompleteService(query) {
      // SE ESTABLECEN LOS PARAMETROS PARA LA PETICION DE BUSQUEDA POR TEXTO
      let request = {
        query: query,
        type: "address",
      };

      // SE REALIZA LA CONSULTA AL SERVICIO DE BUSQUEDA DE LUGARES POR TEXTO
      this.placesService.textSearch(request, (predictions) => {
        // SE OBTIENE LAS LISTA DE RESULTADOS O SE ESTABLECE UN ARREGLO VACIO
        predictions = predictions || [];

        // SE ITERA LA LISTA DE RESULTADOS PARA OBTENER UNICAMENTE LOS CAMPOS REQUERIDOS
        predictions = predictions.map((p) => {
          return {
            address: p.formatted_address,
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
          };
        });

        // SE ESTABLECEN LOS RESULTADOS EN LA LISTA DE SELECCION
        this.autoCompleteItems = predictions;
      });
    },
    /**
     * SE ESTABLECE EL MARCADOR
     */
    addMarker(placeDetails) {
      this.$console.info("ADDMARKER => ", placeDetails);
      if (this.marker != null) {
        this.marker.setMap(null);
      }

      if (placeDetails) {
        // SE OBTIENEN LOS PARAMETROS DE LATITUD Y LONGITUD
        let position = {
          lat: placeDetails.lat,
          lng: placeDetails.lng,
        };

        // SE OBTIENE LA DIRECCION EN FORMATO DE TEXTO
        let addressPlace = placeDetails.formatted_address;

        // SE CREA EL NUEVO MARCADOR
        this.marker = new window.google.maps.Marker({
          position,
          map: this.map,
          draggable: true,
          optimized: false,
          title: addressPlace,
        });

        this.map.setCenter(position);

        window.google.maps.event.addListener(
          this.marker,
          "dragend",
          this.updateMarker2
        );
      }
    },
    updateMarker2(evt) {
      let latLng = evt.latLng;

      const geocoder = new window.google.maps.Geocoder();

      geocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            let places = response.results.map((i) => {
              return {
                address: i.formatted_address,
                lat: i.geometry.location.lat(),
                lng: i.geometry.location.lng(),
              };
            });

            this.$console.info('MAKER => ', places[0]);

            this.address.location = places[0];

            this.autoCompleteItems = places;
          }
        })
        .catch((err) => {
          this.$console.error("Geolocation => ", err);
        });
    },
    cleanAutoCompleteItems() {
      this.autoCompleteItems = [];
    },
  },
};
</script>